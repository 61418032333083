.App {
  text-align: center;
  display: grid;
  grid-template-rows: 20vh 80vh;
  grid-template-columns: 50vw 50vw;
  color: white;
  background-color: #530000;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .App {
    grid-template-columns: 100vh;
    grid-template-rows: 20vh 60vh 20vh;
  }
}

button:hover {
  cursor: pointer;
}
