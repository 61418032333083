.photo {
  grid-row: 1 / 2;
  grid-column: 2;
}

@media screen and (max-width: 800px) {
  .photo {
    position: absolute;
  }
}

.photo-clicked {
  background-color: #530000;
  object-fit: cover;
  clip-path: polygon(8% 100%, 0% 0%, 100% 0%, 100% 100%);
  height: 100vh;
  width: 50vw;
}

@media screen and (max-width: 800px) {
  .photo-clicked {
    grid-column: 1;
    grid-row: 1 / 2;
    position: absolute;
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);
    width: 100vw;
    top: 15vh;
    height: 85vh;
  }
}
