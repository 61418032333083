.header {
  margin: 20px auto;
  width: 45vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
}

@media screen and (max-width: 800px) {
  .header {
    width: 95vw;
    height: 10vh;
    margin: 0 20px 0;
  }
}

.header-unclicked {
  flex-direction: column;
  justify-content: space-around;
  grid-column: 1/3;
  z-index: 200;
}

.header-unclicked:first-child {
  margin-top: 20vh;
}

.logos {
  max-width: 70px;
  padding: 5px;
}

@media screen and (max-width: 1200px) {
  .logos {
    max-width: 40px;
    padding: 3px;
  }
}
@media screen and (max-width: 900px) {
  .logos {
    max-width: 30px;
    padding: 2px;
  }
}

.language {
  text-decoration: none;
  border: none;
  color: white;
  background-color: transparent;
  font-size: 20px;
}

@media screen and (max-width: 800px) {
  .language {
    margin-right: 20px;
  }
}
.language:hover {
  text-decoration: underline;
}

.button {
  text-decoration: none;
  background-color: darkred;
  border: none;
  border-radius: 15px;
  font-size: 30px;
  color: white;
  margin: 20px 0;
  padding: 4px 15px 10px;
  animation: borderPulse 1200ms infinite ease-out;
}

@media screen and (max-width: 1200px) {
  .button {
    font-size: 20px;
    padding: 4px 10px 6px;
    font-weight: 600;
  }
}

@media screen and (max-width: 400px) {
  .button {
    font-size: 15px;
    padding: 4px 10px 6px;
    font-weight: 600;
  }
}

.button:hover {
  background-color: red;
}

@keyframes borderPulse {
  0% {
    box-shadow: inset 0px 0px 0px 4px rgba(255, 255, 255, 0.4),
      0px 0px 0px 0px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: inset 0px 0px 0px 2px rgba(117, 117, 255, 0.2),
      0px 0px 0px 5px rgba(255, 255, 255, 0);
  }
}
