.hide {
  display: none;
}

.body {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin: 0 0 5vh 5vw;
  grid-column: 1;
  grid-row: 2;
}

@media screen and (max-width: 800px) {
  .body {
    position: absolute;
    top: 8vh;
    grid-row: 2/ 4;
    margin: 0;
    width: 100vw;
    z-index: 100;
  }
}

.date {
  display: flex;
  justify-content: baseline;
  margin: 10px 0 5px;
  min-height: 30px;
  color: white;
  font-weight: 700;
}

@media screen and (max-width: 800px) {
  .date {
    min-height: 15px;
    margin: 15px 0 5px 10px;
  }
}
.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding-bottom: 10px;
  background-color: white;
  color: darkred;
  text-decoration: underline;
  font-size: 80px;
  font-weight: 900;
}

@media screen and (max-width: 1200px) {
  .status {
    font-size: 60px;
  }
}

@media screen and (max-width: 800px) {
  .status {
    font-size: 55px;
    margin: 0 0 45vh;
  }
}

.message-text {
  margin: 0;
  padding: 5vh 7vw 7vh;
  font-size: 50px;
  background-color: darkred;
  font-weight: 700;
  text-align: right;
}

@media screen and (max-width: 1200px) {
  .message-text {
    font-size: 40px;
  }
}

@media screen and (max-width: 800px) {
  .message-text {
    padding: 1vh 3vw 2vh;
    font-size: 25px;
  }
}

.message-text-en {
  text-align: left;
}
